import React, { Suspense, lazy } from "react";
import Loading from "./components/Loading/Loading.js";
import { HashRouter as BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

const HomePage = lazy(() => import("./components/HomePage/HomePage.js"));
const EtiquetasPage = lazy(() =>
  import("./components/EtiquetasPage/EtiquetasPage.js")
);
const CodificacionPage = lazy(() =>
  import("./components/CodificacionPage/CodificacionPage.js")
);
const EmbalajePage = lazy(() =>
  import("./components/EmbalagePage/EmbalagePage.js")
);
const MovilesPage = lazy(() =>
  import("./components/MovilesPage/MovilesPage.js")
);

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/etiquetas"
            element={
              <Suspense fallback={<Loading />}>
                <EtiquetasPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/codificacion"
            element={
              <Suspense fallback={<Loading />}>
                <CodificacionPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/embalaje"
            element={
              <Suspense fallback={<Loading />}>
                <EmbalajePage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/soluciones-moviles"
            element={
              <Suspense fallback={<Loading />}>
                <MovilesPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/*"
            element={
              <Suspense fallback={<Loading />}>
                <HomePage />
              </Suspense>
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
