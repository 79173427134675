import PiwikPro, { PageViews, DataLayer } from "@piwikpro/react-piwik-pro";

const initialize = () => {
  PiwikPro.initialize(
    process.env.REACT_APP_PIWIKPRO_ID,
    process.env.REACT_APP_PIWIKPRO_URL
  );
};

const pageView = async (title) => {
  PageViews.trackPageView(`${title}`);
};

const event = async (event) => {
  await DataLayer.push({ event: `${event}` });
};

export const AnalyticService = { initialize, event, pageView };
